import React, { useEffect } from "react";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';
const QuestionResult = (props) => {
  const { question, index,  } = props;
  console.log('options', question.options);
   //let color= option.status ? 'green':'red'
   
 
    let color1 =question.answer ? 'true' : 'false'


    return (
      <div className="question-answer selected-answer">

        <div className="question-answer ">
        <span className= {`question-head-${color1}`}>Q{question.order}- {question.text} {question.answer ? <CheckIcon style={{fill: "green"}}/> : <BlockIcon style={{fill: "red"}}/>}</span>
        
        
       {/* // <CheckCircleIcon className='checked-answer-icon'/> */}
      

         </div>
        {/* className={optionsChecked.optionsId?.includes(option._id)?'checked-answer-icon':'unchecked-answer-icon'}/></div> */}

        <ul>
          {
            question.options.map((option) => {
              return <li className={`student-result-${option.status ? 'true' : 'false'}`}>{option.optionText}</li>
  })
          }
          </ul>
      </div>
    );


};

export default QuestionResult;

