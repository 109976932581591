import Checkbox from '@material-ui/core/Checkbox';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import IconButton from '@material-ui/core/IconButton';

const Option = (props) => {
  const { option, optionIndex, questionIndex, handleChange } = props;
  return (
    <div className="answer-container">
      <Checkbox
        icon={<CircleUnchecked />}
        checkedIcon={<CircleChecked />}
        onChange={(event) => handleChange(event, questionIndex,
          optionIndex, false, true)}
        inputProps={{ 'aria-label': 'primary checkbox' }}
        className="answer-checklist"
        checked={option.status} name="status"
        onChange={(event) => handleChange(event, questionIndex,
          optionIndex, false, true)} />
      <input className="answer-text" value={option.optionText}
        name="optionText"
        onChange={(event) => handleChange(event, questionIndex,
          optionIndex, false, true)} />
      <IconButton className='del-answer-icon'
        onClick={(event) => handleChange(event, questionIndex,
          optionIndex, true)}>
        <DeleteIcon />
      </IconButton>
    </div>
  );
};
export default Option;