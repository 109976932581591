import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Popover from '@material-ui/core/Popover';
import HomeIcon from '@material-ui/icons/Home';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import {useDispatch} from 'react-redux';
import {logout} from '../../redux/slices/user';
import Logo from '../../assets/img/logo.png';
const Profile = () => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const homeUrl=()=>{
    const role=localStorage.getItem('role');
    if(role === 'admin' || role ==='super-admin') {
      return window.location.href = "/user";
    }
    window.location.href = '/student';
  }
  const handleClickProfile=(expression)=> {
    switch(expression) {
      case 'home':
        homeUrl()
        break;
      case 'logout':
          dispatch(logout())
        break;
      default:
        // code block
    }
  }
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
      <div className="profile-information">
        <img src={Logo} width={40} onClick={()=>homeUrl()}/>
      <div className="profile">
        <PersonOutlineIcon />
        <div className="profile-email">{localStorage.getItem('email')}</div>
        <ExpandMoreIcon onClick={handleClick}/>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
        >
          <div className="profile-card">
            <div className="profile-home" onClick={()=>handleClickProfile('home')}>  <HomeIcon/> Home </div>
            <div className="profile-logout" onClick={()=>handleClickProfile('logout')}> <ExitToAppIcon/> logout</div>
          </div>
        </Popover>
      </div>
      </div>
  );
};

export default Profile;