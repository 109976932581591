import React, { Children } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link, NavLink } from "react-router-dom";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import Logo from "../../assets/img/logo.png";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slices/user";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import DescriptionIcon from "@material-ui/icons/Description";

export default function PersistentDrawerLeft({ props }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleClickProfile = () => {
    dispatch(logout());
  };
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className="toolBarGrid">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <img className="image" src={Logo} width={60} />
          <div className="email" style={{ display: "flex" }}>
            <PersonOutlineIcon style={{ color: "gray" }} />
            <div
              style={{ fontWeight: "bold", color: "gray" }}
              className="profile-email"
            >
              {localStorage.getItem("userName")}
              <br />
              {localStorage.getItem("email")}
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>

        <Divider />
        <List>
          {(localStorage.getItem("role") === "admin"
            ? [{ name: "Quiz-Forms", link: "/form" }]
            : [
                { name: "Users", link: "/user" },
                { name: "Quiz-Forms", link: "/form" },
              ]
          )?.map((text, index) => (
            <ListItem
              component={Link}
              to={text.link}
              selected={index === selectedIndex}
              button
              key={index}
              onClick={(event) => handleListItemClick(event, index)}
              style={
                selectedIndex === index ? { backgroundColor: "#D3D3D3" } : {}
              }
            >
              <ListItemIcon>
                {text.name == "Users" ? (
                  <PeopleAltIcon
                    style={selectedIndex === index ? { color: "#40BCF9" } : {}}
                  />
                ) : (
                  <DescriptionIcon
                    style={selectedIndex === index ? { color: "#40BCF9" } : {}}
                  />
                )}
              </ListItemIcon>
              <ListItemText
                style={{ fontWeight: "bold" }}
                primary={
                  <p
                    style={selectedIndex === index ? { color: "#40BCF9" } : {}}
                    className="listText"
                  >
                    {text.name}
                  </p>
                }
              />
            </ListItem>
          ))}
        </List>
        <br />

        <Divider />
        <List>
          {["Logout"].map((text, index) => (
            <ListItem button key={index} onClick={handleClickProfile}>
              <ListItemIcon>
                <ExitToAppIcon style={{ color: "#40BCF9" }} />
              </ListItemIcon>
              <ListItemText primary={<p className="listText">{text}</p>} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {props}
      </main>
    </div>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    fontSize: "50px",

    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,

    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));
