import React from "react";
import Profile from "../../Components/Profile/Profile";
import { Button, ButtonGroup } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import User from "./Form/user";
import { Link } from "react-router-dom";
import PersistentDrawerLeft from "../../Components/navbar/SideNavBar";

export default function index() {
  return (
    <div>
      <PersistentDrawerLeft />
    </div>
  );
}
