import React from "react";

// Import the react editor TinyMCE component.
import { Editor } from "@tinymce/tinymce-react";

// Init the editor and define its options
const options = {
  height: 500,
  menubar: false,

  //Add wiris plugin
  external_plugins: {
    // 'tiny_mce_wiris': 'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js'
    /* Self Hosted  on quiz back */
    'tiny_mce_wiris': 'https://quiz-api.takiacademy.com/uploads/plugin.js'
  },
  plugins: [
    "advlist autolink lists link image charmap print preview anchor",
    "searchreplace visualblocks code fullscreen",
    "insertdatetime media table paste code help wordcount ",
  ],
  toolbar: [
    // for Chemistry formula if you need to use it just uncomment the next Line 
    //tiny_mce_wiris_formulaEditorChemistry
    "tiny_mce_wiris_formulaEditor " +
      "undo redo | formatselect | " +
      "bold italic backcolor | alignleft aligncenter " +
      "alignright alignjustify | bullist numlist outdent indent | " +
      "removeformat | help | | link image | code",
  ],
  htmlAllowedTags: [".*"],
  htmlAllowedAttrs: [".*"],
};

const TextEditor = ({changeText,questionIndex,defaultValue}) => {

  return (
    <>
      <Editor
        apiKey={process.env.TINY_KEY}
        init={options}
        value={defaultValue}
        onEditorChange={(newText) => changeText(newText,questionIndex)}
      />
    </>
  );
};

export default TextEditor;