import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {useDispatch, useSelector} from 'react-redux';
import {unwrapResult} from '@reduxjs/toolkit';
import {getForm, saveQuestions, uploadFile} from '../../../redux/slices/admin';
import Question from '../Questions/Question';
import SaveIcon from '@material-ui/icons/Save';
import {SnackBar} from '../../../Components/SnackBar';
import Profile from '../../../Components/Profile/Profile';
import AddButtonHeader from './AddButtonHeader';


const Form = () => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const {form, message, success} = useSelector((state) => state.admin);
  const [validationMessage, setValidationMessage] = useState();
  useEffect(() => {
    dispatch(getForm(id));
  }, [dispatch]);
  const [localQuestion, setQuestion] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  useEffect(() => {
    setQuestion(form?.questions);
    setValidationMessage('');
  }, [form]);
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {passive: true});
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  //detect scroll position
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const removeMedia = (item, index) => {
    let newArr = JSON.parse(JSON.stringify(localQuestion));
    newArr[index][item] = '';
    setQuestion(newArr);
  };
  console.log(localQuestion,'question')
  const addQuestion = () => {
    setQuestion((localQuestion) => [
      ...localQuestion,
      {
        options: [
          {
            optionText: '',
            status: true,
          },
          {
            optionText: '',
            status: false,
          },
        ],
        multiple: false,
        questionText: '',
        questionDescription: '',
        order: localQuestion.length + 1,
        questionImage: '',
        questionAudio: '',
        questionVideo: '',
      },
    ]);
  };
  const handleChange = (
      e,
      questionIndex,
      optionIndex,
      remove = false,
      options = false,
  ) => {
    let newArr = JSON.parse(JSON.stringify(localQuestion));
    if (e.target.name == 'multiple') {
      if (!e.target.checked) {
        newArr[questionIndex]['options'].map((o) => {
          o.status = false;
        });
        setQuestion(newArr);
      }
    }

    if (remove) {
      let questionOptions = newArr[questionIndex].options.filter(
          (option, index) => {
            return index !== optionIndex;
          },
      );
      newArr[questionIndex].options = questionOptions;
    } else {
      if (e.target.id === 'add-choice') {
        newArr[questionIndex]['options'].push({
          optionText: '',
          status: false,
        });
      } else if (options) {
        if (!newArr[questionIndex]['multiple']) {
          if (
              newArr[questionIndex]['options'].filter(
                  (op) => op.status === true)
          ) {
            newArr[questionIndex]['options'].map((o) => {
              o.status = false;
            });
            setQuestion(newArr);
          }
        }
        newArr[questionIndex]['options'][optionIndex][e.target.name] =
            e.target.type === 'checkbox' ? e.target.checked : e.target.value;
      } else {
        newArr[questionIndex][e.target.name] =
            e.target.type === 'checkbox' ? e.target.checked : e.target.value;
      }
    }
    setQuestion(newArr);
  };
  const handleChangeDescription = (value, questionIndex) => {
    let newArr = JSON.parse(JSON.stringify(localQuestion));
    newArr[questionIndex]['questionDescription'] = value || '';
    setQuestion(newArr);
  };
  const handleChangeUpload = async (file, questionIndex, type) => {
    let attribute;
    switch (type) {
      case 'audio':
        attribute = 'questionAudio';
        break;
      case 'image':
        attribute = 'questionImage';
        break;
      default:
        // code block
    }
    let newArr = JSON.parse(JSON.stringify(localQuestion));
    let result;
    if (attribute != 'link') {
      let resultAction = await dispatch(uploadFile(file[0]));
      result = await unwrapResult(resultAction);
    }
    newArr[questionIndex][attribute] =
        attribute === 'link' ? file : result.path;
    setQuestion(newArr);
  };
  const handleChangeLink = (value, questionIndex) => {
    let newArr = JSON.parse(JSON.stringify(localQuestion));
    newArr[questionIndex].questionVideo = value;
    setQuestion(newArr);
  };
  const generateValidationMessage = (attribute, index, optionIndex) => {
    let message;
    if (!optionIndex) {
      message = ` Question ${index} ${attribute} Not valid`;
    } else {
      message = ` Question ${index} Option ${optionIndex} Not valid`;
    }
    setValidationMessage(message);
  };
  const inputValidation = (questions) => {
    let result = true;
    questions.map((question, index) => {
      // if (
      //   !question.questionDescription ||
      //   question.questionDescription === "<p></p>\n"
      // ) {
      //   generateValidationMessage("Description", index + 1);
      //   result = false;
      // }
      if (!question.questionText) {
        generateValidationMessage('Title', index + 1);
        result = false;
      }
      question.options.map((option, optionIndex) => {
        if (!option.optionText) {
          generateValidationMessage('Option Title', index + 1, optionIndex + 1);
          result = false;
        }
      });
    });
    return result;
  };
  const uploadFiles = async () => {
    let newArr = JSON.parse(JSON.stringify(localQuestion));
    for (let i = 0; i < localQuestion.length; i++) {
      if (
          localQuestion[i].questionImage &&
          typeof localQuestion[i].questionImage != 'string'
      ) {
        let resultAction = await dispatch(
            uploadFile(localQuestion[i].questionImage),
        );
        const result = await unwrapResult(resultAction);
        newArr[i].questionImage = result.path;
      }
      if (
          localQuestion[i].questionAudio &&
          typeof localQuestion[i].questionAudio != 'string'
      ) {
        let resultAction = await dispatch(
            uploadFile(localQuestion[i].questionAudio),
        );
        const result = await unwrapResult(resultAction);
        newArr[i].questionAudio = result.path;
      }
    }
    return newArr;
  };
  const submitQuestions = async () => {
    if (!inputValidation(localQuestion)) {
      setOpenSnackBar(true);
      return;
    }
    const newArr = await uploadFiles();
    await dispatch(saveQuestions(id, newArr));
    setOpenSnackBar(true);
    window.location = '/form';
  };
  const handleRemoveQuiz = (questionIndex) => {
    let newArr = localQuestion.filter(
        (question, index) => index != questionIndex,
    );
    setQuestion(newArr);
  };
  return (
      <div>
        <Card className="question-card" key={id}>
          <CardContent className="question-container">
            <Profile/>
            <AddButtonHeader
                buttonFn={addQuestion}
                buttonText={'Create new question'}
                headerTitle={'Quiz'}
                scrollPosition={scrollPosition}
                link={'/'}
            />
            <div className="questions">
              <div className="questions-form">
                {localQuestion && (
                    <div
                        className="submit-questions-button"
                        onClick={submitQuestions}
                        style={{
                          position: scrollPosition > 160 ? 'fixed' : 'relative',
                          right: scrollPosition > 135 ? 0 : 'null',
                          bottom: scrollPosition > 135 ? 0 : 'null',
                          background: scrollPosition > 160 ?
                              'transparent' :
                              '#F4F6F6',

                        }}
                    >
                      <div className="submit-questions-text">
                        Submit Questions <SaveIcon/>
                      </div>
                    </div>
                )}
                <div className="questions-form-title">Quiz-Name: {form?.name}</div>
                <div className="questions-form-id">ID: {form?._id}</div>
              </div>
              <div className="questions-cards">
                {localQuestion &&
                localQuestion.map((question, index) => {
                  return (
                      <>
                        <Question
                            question={question}
                            handleChange={handleChange}
                            questionIndex={index}
                            changeDescription={handleChangeDescription}
                            removeQuiz={handleRemoveQuiz}
                            handleChangeUpload={handleChangeUpload}
                            handleChangeLink={handleChangeLink}
                            removeMedia={removeMedia}
                        />
                      </>
                  );
                })}
              </div>
              {localQuestion && localQuestion.length && (
                  <AddButtonHeader
                      buttonFn={addQuestion}
                      buttonText={'Create new question'}
                      scrollPosition={scrollPosition}
                      buttonPoisition={'flex-start'}
                      link={'/'}
                  />
              )}
            </div>
          </CardContent>
        </Card>
        <SnackBar
            open={openSnackBar}
            setOpen={setOpenSnackBar}
            snackBarText={validationMessage || message}
            snackSeverity={validationMessage ? false : success}
        />
      </div>
  );
};

export default Form;
