import React from "react";
import { Button, Card, Container } from "@material-ui/core";
import { useSelector } from "react-redux";

const FormModal = (props) => {
  const {form} = useSelector(
    (state) => state.student);
  const { modalValues, modalSetValues } = props;
  const { name, description, publish } = modalValues;
  const handleChange = (event) => {
    modalSetValues((prevState) => ({
      ...prevState,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    }));
  };
  return (
    
      <p className='btnTitle' style={{ color: "white", marginTop: "40px" }}>
        {" "}
        Welcome to {form?.name} Quiz Game
        
      </p>
  );
};

export default FormModal;
