import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "./DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
export default function CustomizedDialogs(props) {
  const {
    classN,
    titreBtn,
    open,
    setOpen,
    ModalComponent,
    modalAction,
    modalValues,
    modalSetValues,
    btnTitel,
  } = props;
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        className={classN}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          className={classN}
          id="customized-dialog-title"
          
        >{btnTitel}</DialogTitle>
        <DialogContent className={classN} dividers>
          <ModalComponent
            modalValues={modalValues}
            modalSetValues={modalSetValues}
          />
        </DialogContent>
        <DialogActions className={classN} style={{ justifyContent: "center" }}>
          <Button autoFocus onClick={modalAction} className="buttons">
            {titreBtn}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
