import {configureStore} from '@reduxjs/toolkit';
import admin from './slices/admin';
import student from './slices/student';
import user from './slices/user';
import superAdmin from './slices/superAdmin';

export default configureStore({
  reducer: {
    admin,
    student,
    user,
    superAdmin,
  },
});
