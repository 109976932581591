import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

// Slice
const slice = createSlice({
  name: 'admin',
  initialState: {
    forms: [],
    isLoading: false,
    error: false,
    filePath: null,
    formId:null
  },
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    formsSuccess: (state, action) => {
      state.forms = action.payload.data;
      state.isLoading = false;
      state.formId=null
    },
    changeFormResult: (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message
      state.success = action.payload.success
      state.formId=action.payload.formId
    },
    formSuccess: (state, action) => {
      state.form = action.payload.data;
      state.isLoading = false;
    },
    saveQuestionsSuccess: (state, action) => {
      state.form = action.payload.data;
      state.isLoading = false;
    },
  }
});
export default slice.reducer;
// Actions
const { formsSuccess, startLoading, hasError, changeFormResult, formSuccess } = slice.actions;

export const getForms = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api.get('forms').
      then((response) => dispatch(formsSuccess(response.data)));
  } catch (e) {
    return dispatch(hasError(e.message));
  }
};
export const AddForm = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api.post('form', data).
      then((response) => {
        dispatch(changeFormResult(response.data));
        dispatch(getForms());
      })
      ;
  } catch (e) {
    return dispatch(changeFormResult(e.message));
  }
};
export const editForm = (id, data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api.put(`form/${id}`, data).
      then((response) => {
        dispatch(changeFormResult(response.data));
        dispatch(getForms());
      })
      ;
  } catch (e) {
    return dispatch(changeFormResult(e.message));
  }
};
export const getForm = (id) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api.get(`form/${id}`).
      then((response) => {
        dispatch(formSuccess(response.data));
      });
  } catch (e) {
    return dispatch(hasError(e.message));
  }
};

export const saveQuestions = (id, data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api.post(`form/${id}/content`, { questions: data }).
      then((response) => {
        dispatch(changeFormResult(response.data));
        dispatch(getForm(id));
      });
  } catch (e) {
    return dispatch(changeFormResult(e.message));
  }
};

export const deleteForm = (id,publish) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api.delete(`form/${id}?publish=${publish}`).
      then((response) => {
        dispatch(changeFormResult(response.data));
        dispatch(getForms());
      });
  } catch (e) {
    return dispatch(changeFormResult(e.message));
  }
};

export const uploadFile = createAsyncThunk(
  'admin/uploadFile',
  async (file) => {
    const formData = new FormData();
    const config = { headers: { 'enctype': 'multipart/form-data' } };
    formData.append('image', file);
    const result = await api.post(`/fileUpload`, formData, config);
    return result.data;
  }
)