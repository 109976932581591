import React, { useEffect, useState } from "react";
import CardContent from "@material-ui/core/CardContent";
import { useDispatch, useSelector } from "react-redux";
import {
  AddForm,
  deleteForm,
  editForm,
  getForms,
} from "../../redux/slices/admin";
import Dialog from "../../Components/Dialog/Dialog";
import FormModal from "./Forms/FormModal";
import { SnackBar } from "../../Components/SnackBar";
import AddButtonHeader from "./Forms/AddButtonHeader";
import Switch from "@material-ui/core/Switch";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PersonIcon from "@material-ui/icons/Person";
import InputIcon from "@material-ui/icons/Input";
import { Button, Container } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { createTheme } from '@material-ui/core/styles';

const Index = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [values, setValues] = useState({
    name: "",
    description: "",
    publish:false
  });

  const { forms, message, success, formId } = useSelector(
    (state) => state.admin
  );

  useEffect(() => {
    dispatch(getForms());
  }, [dispatch]);
  const submitForm = async () => {
    if (!values.id) {
      await dispatch(AddForm(values));
    } else {
      await dispatch(editForm(values.id, values));
    }
    setOpen(false);
    setOpenSnackBar(true);
    setValues({
      name: "",
      description: "",
    });
  };
  const handleFormAction = (form, action, checked) => {
    switch (action) {
      case "edit":
        setValues({
          name: form.name,
          description: form.description,
          id: form._id,
        });
        setOpen(true);
        break;
      case "delete":
        dispatch(deleteForm(form._id, ""));
        setOpenSnackBar(true);
        break;
      case "draft":
        dispatch(deleteForm(form._id, !checked));
        break;
      case "switch":
        window.open(`/student/quiz/${form._id}`);
        break;
      case "switchAdmin":
        window.open(`/create/quiz/${form._id}`);
        break;
      default:
    }
  };
  

  const headCells = [
    {
      field: "_id",
      flex: 1,
      headerName: "ID",
      filter: "agTextColumnFilter",
      resizable: true,
      sortable:true,
      
    },
    {
      field: "name",
      flex: 1,
      headerName: "Titel",
      filter: "agTextColumnFilter",
      sortable: true,
      resizable: true,
    },

    {
      field: "createdAt",
      resizable: true,
      headerName: "CreatedAt",
      flex: 1,
      sortable: true,
      filter: "agDateColumnFilter",
      alwaysShowBothConditions:true,

      filterParams:(e)=>filterParams,
      valueGetter:(e) => e?.data?.createdAt.substring(0, 10).split('-').reverse().join("/"),
    },
    {
      field: "updatedAt",
      resizable: true,
      headerName: "UpdatedAt",
      flex: 1,
      filter: "agDateColumnFilter",
      sortable:true,

      filterParams:()=>filterParams,
      valueGetter:(e) => e?.data?.createdAt.substring(0, 10).split('-').reverse().join("/"),
    },
    {
      field: "createdBy",
      flex: 1,
      headerName: "CreatedBy",
      resizable: true,
      sortable: true,
      filter: true,
      valueGetter:(e) => e?.data?.createdBy?.fullName,
     
     
    },
    {
      field: "publish",
      headerName: "Active/Desactive",
      resizable: true,
      sortable: true,
      flex: 1,

      valueFormatter: ({ value }) => value?.publish,
      cellRendererFramework: (form) => {
        return (
           <Switch
            checked={form?.data?.publish}
             onChange={() =>
               handleFormAction(form?.data, "draft", form?.data.publish)
            }
            name="publish"
            inputProps={{
              "aria-label": "secondary checkbox",
            }}
          />
        );
      },
    },

    {
      field: "actions",

      headerName: "Actions",
      width: 270,
      cellRendererFramework: (form) => {
        return  (
          <div className="form-list-actions">
            <Button title='Edit-Quiz'>
              <InputIcon
                className="form-list-icon"
                onClick={() => handleFormAction(form.data, "switchAdmin")}
              />
            </Button>
            <Button title='Student-seen'>
              <PersonIcon
                className="form-list-switch"
                onClick={() => handleFormAction(form.data, "switch")}
              />
            </Button>
            <Button title='Edit'>
              {" "}
              <EditIcon 
                className="form-list-edit"
                onClick={() => handleFormAction(form.data, "edit")}
              />
            </Button>
            <Button title='Delete'>
              <DeleteIcon
                className="form-list-delete"
                onClick={() => handleFormAction(form.data, "delete")}
              />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyItems: "center",
        justifyContent: "center",
      }}
    >
      <Container className="form-card">
        <CardContent
          className="form-container"
          style={{
            padding: 0,
          }}
        >
          <AddButtonHeader
            buttonFn={() => setOpen(true)}
            buttonText={"Create new form"}
           
          />
          <div
            className="ag-theme-alpine"
            style={{ height: 730, width: "100%" }}
          >
            <AgGridReact
              rowData={forms}
              columnDefs={headCells}
              pagination={true}
              paginationPageSize={15}
            ></AgGridReact>
            
          </div>
          <Dialog
            open={open}
            setOpen={setOpen}
            ModalComponent={FormModal}
            modalAction={submitForm}
            modalValues={values}
            modalSetValues={setValues}
            titreBtn='save changes'
            btnTitel="Create new Quiz:"
            alwaysShowBothConditions={false}
          />
          <SnackBar
            open={openSnackBar}
            setOpen={setOpenSnackBar}
            snackBarText={message}
            snackSeverity={success}
            formId={formId}
          />
        </CardContent>
      </Container>
    </div>
  );
};

export default Index;




var filterParams = {
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split('/');
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};
