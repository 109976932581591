import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Link } from "react-router-dom";


const AddButtonHeader = ({buttonFn, headerTitle, buttonText, scrollPosition, buttonPoisition,link}) => {

  return (
      <div className="form-header" style={{
        justifyContent: buttonPoisition,
      }} >
        <Link to={link} className="add-form-text">
          {headerTitle}
        </Link>
        <div className="add-form" onClick={buttonFn}>
          <AddIcon/>
          {buttonText}
        </div>
      </div>
  );
};
export default AddButtonHeader;