import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 13,
        borderRadius: 8,
        minWidth: '58%'
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        background: 'linear-gradient(315deg, #39e5b6  0%, #70b2d9 74%);',
    },
}))(LinearProgress);

export default BorderLinearProgress;