import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const Option = (props) => {
  const {option, handleOptions,optionsChecked} = props;
        return (
            <div
                className={optionsChecked?.optionsId?.includes(option._id)? "question-answer selected-answer" :"question-answer"}
                onClick={(event) => (handleOptions(event,
                    option))}> {option.optionText} <CheckCircleIcon
                className={optionsChecked.optionsId?.includes(option._id)?'checked-answer-icon':'unchecked-answer-icon'}/></div>
        );
};

export default Option;