import React, { useEffect } from "react";
import { Doughnut } from "@reactchartjs/react-chart.js";
import { useParams } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { getResult } from "../../redux/slices/student";
import { useDispatch, useSelector } from "react-redux";
import { element } from "prop-types";
import HomeIcon from "@material-ui/icons/Home";
import QuestionResult from "./QuestionResult";
const QuizResult = () => {
  let quizElementfinal = [];
  let opts= [];
  let trueAnswsers =0;
  const { userId, formId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getResult(formId, userId));
  }, [dispatch]);

  const { quizResults, isLoading } = useSelector((state) => state.student);

  {
    let quizElement = [];
   
    
    quizResults &&
      quizResults.map((result,index) => {

        var obj = {};
        let opts= {};
        opts = result.questionId.options;
        obj["options"] = opts;
        obj["order"]=index+1
        obj["text"]=result.questionId.questionText
        if(result.questionId.multiple && result.optionsId.length < 2 )
        {
          obj["answer"]=false
        }
        else
        {
          obj["answer"]=true
        }
        
        result.optionsId.map( (option,i) => {
          if(option.status)
          {
              obj["answer"]= obj["answer"] && option.status
              if(obj["answer"])
              trueAnswsers++

         }
         else
         {
          obj["answer"]=false
         }
        })

        quizElement.push(obj);

       
      });
      quizElementfinal=quizElement
      console.log('result', quizElementfinal);
  }
  return (
    <div className="student-forms">
      {/* <HomeIcon style={{color:'#18698D',fontSize:35 ,cursor:'pointer'}} onClick={()=>(window.location=`/student`)}/> */}
      {/* <Doughnut data={data}/> */}
     
      <h1>Result <span style={{color:'green'}}>{trueAnswsers}</span>/{quizElementfinal.length}</h1>
      <div className="list-form">
        <div className="student-form">
          {quizElementfinal &&
            quizElementfinal.map((element, index) => {
              return <QuestionResult question={element} index={index}  />;
            })}
        </div>
      </div>
    </div>
  );
};
export default QuizResult;
