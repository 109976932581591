import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

export function SnackBar({ open, setOpen, snackBarText, snackSeverity , formId } ) {
  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <Alert onClose={() => setOpen(false)} severity={snackSeverity ? "success" : "error"}>
          {snackBarText}
        </Alert>
      </Snackbar>
      {open && formId && window.open(`create/quiz/${formId}`)}
    </div>
  );
}