import { createSlice } from "@reduxjs/toolkit";
import api from "../../services/api";

const initialUser = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;

// Slice
const slice = createSlice({
  name: "user",
  initialState: {
    user: initialUser,
    informations: null,
    isLoading: false,
    message: "",
    success: null,
  },
  reducers: {
    startLoading: (state, action) => {
      state.isLoading = true;
    },
    loginSuccess: (state, action) => {
      state.user = action.payload.data;
      state.message = action.payload.data.message;
      state.success = true;
    },
    loginError: (state, action) => {
      state.message = action.payload.data.message;
      state.success = action.payload.data.status !== 401 ? true : false;
    },
    logoutSuccess: (state, action) => {
      localStorage.removeItem("token");
    },
  },
});
export default slice.reducer;
// Actions
const { loginSuccess, loginError } = slice.actions;
export const login =
  ({ email, password, token, url = null }) =>
  async (dispatch) => {
    try {
      const res = await api.post(`/login?token=${token}`, { email, password });
      if (res.data.status === 401) {
        dispatch(loginError(res));
      } else {
        // localStorage.setItem('user', JSON.stringify(action.payload.user_id));
        localStorage.setItem("role", res.data.data.role);
        localStorage.setItem("user_id", res.data.data._id);
        localStorage.setItem("email", res.data.data.email);
        localStorage.setItem("userName", res.data.data.fullName);
        localStorage.setItem("token", res.data.data.token);
        if (!res.data.source) {
          const location = res.data.data.role === "user" ? "/student" :res.data.data.role==="admin"? "/form": "/user";
          window.location = location;
        }
        // dispatch(loginSuccess(res.data.data));
        if (url) {
          return (window.location = url);
        }
      }
    } catch (e) {
      return console.error(e.message);
    }
  };

export const logout = () => async (dispatch) => {
  localStorage.removeItem("token");
  localStorage.removeItem("role");
  localStorage.removeItem("user_id");
  localStorage.removeItem("email");
  localStorage.removeItem("userName");
  localStorage.removeItem("token");
  window.location = "/";
};
