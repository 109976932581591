import React from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { Create, Login } from "../Pages";
import { Forms } from "../Pages";
import { Result } from "../Pages";
import Form from "../Pages/Admin/Forms/Form";
import Quiz from "../Pages/Student/Quiz";
import PrivateRoute from "./PrivateRoute";
import { SuperAdmin } from "../Pages/index";
import User from "../Pages/SuperAdmin/Form/user";

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <PrivateRoute path="/form">
        <SuperAdmin/> 
        <Switch>
          <Route path="/form" exact component={Create} />
        </Switch>
      </PrivateRoute>
      <PrivateRoute path="/student" exact component={Forms} />
      <PrivateRoute path="/student/quiz/:id" exact component={Quiz} />
      <PrivateRoute path="/create/quiz/:id" exact component={Form} />
      <PrivateRoute path="/answer/:formId/:userId" exact component={Result} />
      <PrivateRoute path="/user">
        <Router>
          <SuperAdmin />
          <Switch>
            <Route path="/user" exact component={User} />
            <Route path="/form" exact component={Create} />
          </Switch>
        
        </Router>
      </PrivateRoute>
    </Switch>
  );
};

export default Routes;
