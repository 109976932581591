import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../utilities/auth';
import {useDispatch, useSelector} from 'react-redux';
import {login} from '../redux/slices/user';
import queryString from 'query-string';

 function PrivateRoute({ component: Component, allowedAuthorities, authorityKey,location, ...rest }) {
  const user  = localStorage?.getItem('token');
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const id=params.get('id')
  
   const dispatch = useDispatch();
   if(token!==null &&  !isAuthenticated(user) ) {
   token!==null && localStorage.setItem("token", token);
      localStorage.setItem("user_id",id)
       //dispatch(login({ email: null, password: null,token:token, url: window.location.pathname }));
    // eslint-disable-next-line react-hooks/rules-of-hooks
  }
  return (
      <Route
          {...rest}
          render={(props) =>
              isAuthenticated(user) || localStorage.getItem('token')!==null  ? (
                  <Component {...props} />
              ) : (
                  <Redirect to="/" />
              )
          }
      />
  );
}

export default PrivateRoute;
