import React, { useEffect, useState } from "react";
import BorderLinearProgress from "../../Components/BorderLinearProgress";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useDispatch, useSelector } from "react-redux";
import { getForm, submitAnswer } from "../../redux/slices/student";
import { useParams } from "react-router-dom";
import Option from "./Option";
import parse from "html-react-parser";
import ReactPlayer from "react-player";
import { SnackBar } from "../../Components/SnackBar";
import Dialog from "../../Components/Dialog/Dialog";
import FormModal from "./FormModal";

const Quiz = () => {
  const { id } = useParams();
  const { form } = useSelector((state) => state.student);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [show, setShow] = useState(true);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(true);
  const [optionsChecked, setOptionsChecked] = useState({
    questionId: "",
    optionsId: [],
  });
  const [values, setValues] = useState({
    fullName: "",
    role: "",
    email: "",
    password: "",
  });
  const user_id = localStorage.getItem("user_id");
  const [questionAnswers, setQuestionAnswers] = useState({
    responses: [],
    userId: user_id,
    formId: id,
  });
  const submitForm = () => {
    setShow(false);
  };
  const handleCheckOption = (event, option) => {
    if (form?.questions[questionIndex].multiple) {
      let newOptions = JSON.parse(JSON.stringify(optionsChecked));
      if (newOptions.optionsId.indexOf(option._id) === -1) {
        newOptions.optionsId.push(option._id);
        newOptions.questionId = form?.questions[questionIndex]._id;
        setOptionsChecked(newOptions);
      } else {
        const filteredOption = newOptions.optionsId.filter(
          (op) => op != option._id
        );
        newOptions.optionsId = filteredOption;
        setOptionsChecked(newOptions);
      }
    } else {
      setOptionsChecked({
        questionId: form?.questions[questionIndex]._id,
        optionsId: [option._id],
      });
    }
  };
  const nextQuiz = () => {
    let responses = questionAnswers.responses;
    // console.log(re)
    // const responseIndex = responses.indexOf(optionsChecked.questionId);
    // console.log(optionsChecked.questionId,responses)
    //  const responseIndex = responses.map(function(e) { return e.questionId; }).indexOf(optionsChecked.questionId);
    //  console.log(responseIndex,'index')
    if (responses[questionIndex]?.optionsId) {
      responses[questionIndex].optionsId = optionsChecked.optionsId;
      responses[questionIndex].questionId = optionsChecked.questionId;
    } else {
      responses.push(optionsChecked);
    }

    setQuestionAnswers({
      formId: id,
      userId: user_id,
      responses: responses,
    });
    setOptionsChecked({ questionId: "", optionsId: [] });
    if (questionIndex + 1 === form?.questions?.length) {
      return submitQuiz();
    }

    setQuestionIndex(questionIndex + 1);
  };
  const submitQuiz = async () => {
    const validationResult = answersValidation(questionAnswers);
    if (validationResult.valid) {
      await dispatch(submitAnswer(questionAnswers));
      return (window.location.href = `/answer/${id}/${user_id}`);
    }
    setOpen(true);
    return setSnackBarMessage(
      `Veuillez répondre à la question ${validationResult?.index}`
    );
  };
  const answersValidation = (questions) => {
    let validation = { valid: true };
    for (let i in questions?.responses) {
      if (!questions?.responses[i].optionsId.length) {
        validation.valid = false;
        validation.index = +i + 1;
      }
    }
    return validation;
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getForm(id));
  }, [dispatch]);

  const changeQuestion = (offset) => {
    const changeCondtion =
      offset === 1
        ? questionIndex + 1 < form?.questions?.length
        : questionIndex > 0;
    if (changeCondtion) {
      setQuestionIndex(questionIndex + offset);
      setOptionsChecked({
        questionId: form.questions[questionIndex]._id,
        optionsId: questionAnswers.responses
          ? questionAnswers.responses[questionIndex + offset]?.optionsId
          : [],
      });
    }
  };
  return (
    <div>
      {/* uncomment this if we will use our app without iframe */}
      {/* <Profile/> */}
      <div className="student-question-card-container">
        <div className="student-question-card">
          <div className="student-question-card-header">
            <div className="question-number">
              <div className="question-index">
                Question {questionIndex + 1}/ {form?.questions?.length}
              </div>
              <div className="change-question-index">
                <ChevronLeftIcon
                  fontSize="large"
                  className={
                    questionIndex === 0
                      ? "change-question-icon disabled-icon"
                      : "change-question-icon"
                  }
                  onClick={() => changeQuestion(-1)}
                />
                <ChevronRightIcon
                  fontSize="large"
                  className={
                    questionIndex + 1 === form?.questions?.length
                      ? "change-question-icon disabled-icon"
                      : "change-question-icon"
                  }
                  onClick={() => changeQuestion(1)}
                />
              </div>
            </div>
            <div className="question-progress">
              <BorderLinearProgress
                variant="determinate"
                value={((questionIndex + 1) / form?.questions?.length) * 100}
              />
            </div>
            <div className="question-title">
              {" "}
              {form?.questions[questionIndex]?.questionText}
            </div>
            {form?.questions[questionIndex]?.questionDescription && (
              <div className="question-description">
                {" "}
                {parse(
                  `${form?.questions[questionIndex]?.questionDescription}`
                )}
              </div>
            )}
            {form?.questions[questionIndex]?.questionImage && (
              <div className="question-media">
                {/*<ReactPlayer url={'http://'+form?.questions[questionIndex]?.questionImage}*/}
                {/*             controls={true}/>*/}
                <img
                  src={form?.questions[questionIndex]?.questionImage}
                  width={200}
                />
              </div>
            )}
            {form?.questions[questionIndex]?.questionVideo && (
              <div className="question-media">
                <ReactPlayer
                  url={form?.questions[questionIndex]?.questionVideo}
                  controls={true}
                />
              </div>
            )}
            {form?.questions[questionIndex]?.questionAudio &&
              !form?.questions[questionIndex]?.questionVideo && (
                <div className="question-audio-player">
                  <ReactPlayer
                    url={form?.questions[questionIndex]?.questionAudio}
                    controls={true}
                  />
                </div>
              )}
          </div>
          <div className="question-answers">
            {form?.questions[questionIndex]?.options.map((option) => {
              return (
                <Option
                  option={option}
                  handleOptions={handleCheckOption}
                  optionsChecked={optionsChecked}
                />
              );
            })}

            <div className="submit-answer" onClick={nextQuiz}>
              <div className="submit-text">
                {questionIndex + 1 === form?.questions?.length
                  ? "Soumettre"
                  : "Suivant"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        classN="backImage"
        open={show}
        setOpen={setShow}
        modalAction={submitForm}
        ModalComponent={FormModal}
        modalValues={values}
        modalSetValues={setValues}
        titreBtn={<p className='btnTitle'>Start Quiz</p>}
      />

      <SnackBar
        open={open}
        setOpen={setOpen}
        snackBarText={snackBarMessage}
        snackSeverity={false}
      />
    </div>
  );
};

export default Quiz;
