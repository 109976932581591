import React from 'react';
import Option from './Option';

const Options = (props) => {
  const { options, questionIndex, handleChange } = props;
  return (
    <>
      <div className="question-answer-header">
        <div className="type-answer-header"> True/False</div>
        <div className="value-answer-header"> Answer</div>
      </div>

      {options.map((option, index) => {
        return (
          <Option option={option} optionIndex={index} questionIndex={questionIndex} handleChange={handleChange} />
        )
      })}
    </>
  );
};

export default Options;