import React, { useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { login } from '../../redux/slices/user';
import {Link, useParams} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {SnackBar} from '../../Components/SnackBar';

const Index = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [openSnackBar,setOpen]=useState(false);
  const {user,success,message} = useSelector(
      (state) => state.user);
  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(login({ email: email, password: password}));
    setOpen(true);
  };
  return (
      <div className="centered-box">
        {/*<span className="title">Asselama</span>*/}
        <span className="label">Adresse Email</span>
        <input
            className="input"
            type="email"
            placeholder="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
        />
      {/*  <span className="label">*/}
      {/*  Mot de passe . <Link to="#">Oublié?</Link>*/}
      {/*</span>*/}
        <input
            className="input"
            placeholder="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
        />
        <Button variant="contained" disableElevation onClick={(e) => handleSubmit(e)}>
          Connecter
        </Button>
        <SnackBar open={openSnackBar} setOpen={setOpen} snackBarText={message} snackSeverity={success}/>
      </div>
  );
};

export default Index;
