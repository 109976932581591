import React, {useEffect, useRef} from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Options from './Options';
import {Controller, useForm} from 'react-hook-form';
import TextEditor from '../../../Components/TextEditor';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FileDropeZone from '../../../Components/FileDropeZone/FileDropeZone';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import ReactPlayer from 'react-player';
import ClearIcon from '@material-ui/icons/Clear';

const Question = (props) => {
      const {
        question,
        questionIndex,
        handleChange,
        changeDescription,
        removeQuiz,
        handleChangeUpload,
        handleChangeLink,
        removeMedia,
      } = props;
      const {watch, control} = useForm({
        mode: 'onChange',
      });
      const isInitialMount = useRef(true);
      useEffect(() => {
        if (isInitialMount.current) {
          isInitialMount.current = false;
        } else {
          changeDescription(watch('editor_content'), questionIndex);
        }
      }, [watch('editor_content')]);
      return (
          <div className="question">
            <DeleteOutlineIcon className="delete-question"
                               onClick={() => removeQuiz(questionIndex)}/>
            <div className="question-container-header">
              <label htmlFor="question-title"
                     className="question-title-label">Question</label>
              <input type="text" className="question-title"
                     placeholder={'Option Title .... ?'}
                     value={question.questionText}
                     onChange={(event) => handleChange(event, questionIndex)}
                     name="questionText"/>
              <FormControlLabel
                  control={
                    <Switch
                        checked={question.multiple}
                        onChange={(event) => handleChange(event, questionIndex)}
                        name="multiple"
                        color="secondary"
                    />
                  }
                  label="Multiple"
              />
            </div>
            <div className="question-description">
              <div className="description-label">Description</div>
              <Controller
                  as={<TextEditor defaultValue={question.questionDescription} changeText={changeDescription} questionIndex={questionIndex}/> } 
                  name="editor_content"
                  control={control}
              />
            </div>
            <FileDropeZone setFiles={handleChangeUpload}
                           questionIndex={questionIndex} type={'image'}/>
            {question.questionImage &&
            <div className="question-image"
                 onClick={() => removeMedia('questionImage', questionIndex)}>
              <img src={question.questionImage} className="question-image"/>
              {/* <ClearIcon className="clear-icon"/> */}
            </div>}
            <FileDropeZone setFiles={handleChangeUpload}
                           questionIndex={questionIndex} type={'audio'}/>
            {question.questionAudio &&
            <div className="question-audio-player">
              <ReactPlayer
                  url={question.questionAudio}
                  controls={true}/>
              <ClearIcon className="clear-icon"
                         onClick={() => removeMedia('questionAudio', questionIndex)}/>
            </div>
            }
            <div className="question-link-video"
                 onChange={(event) => handleChangeLink(event.target.value,
                     questionIndex)}>
              <LinkOutlinedIcon className="link-icon"/>
              <input placeholder="add link video here"
                     className="link-video-input"
                     value={question.questionVideo}/>
            </div>
            <div className="question-answers">
              <Options options={question?.options} questionIndex={questionIndex}
                       handleChange={handleChange}/>
            </div>
            <div className="add-choice-button" id="add-choice"
                 onClick={(event) => handleChange(event,
                     questionIndex)}>Add Choice
            </div>
          </div>
      );
    }
;

export default Question;