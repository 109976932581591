import { createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

// Slice
const slice = createSlice({
  name: 'student',
  initialState: {
    forms: [],
    isLoading: false,
    error: false,
    answerResult: '',
    quizResults: null

  },
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    formsSuccess: (state, action) => {
      state.forms = action.payload.data;
      state.isLoading = false;

    },
    formSuccess: (state, action) => {
      state.form = action.payload.data;
      state.isLoading = false;
    },
    submitAnswerSuccess: (state, action) => {
      state.answerResult = action.payload.data;
      state.isLoading = false;
    },
    getResultSuccess: (state, action) => {
      state.quizResults = action.payload.data;
      state.isLoading = false;
    }
  },
});
export default slice.reducer;
// Actions
const { formsSuccess, startLoading, hasError, formSuccess, submitAnswerSuccess, getResultSuccess } = slice.actions;

export const getForms = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api.get('forms').
      then((response) => dispatch(formsSuccess(response.data)));
  } catch (e) {
    return dispatch(hasError(e.message));
  }
};

export const getForm = (id) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api.get(`form/${id}`).
      then((response) => {
        dispatch(formSuccess(response.data));
      });
  } catch (e) {
    return dispatch(hasError(e.message));
  }
};

export const submitAnswer = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api.post(`/answer/`, data).
      then((response) => {
        dispatch(submitAnswerSuccess(response.data));
      });
  } catch (e) {
    return dispatch(hasError(e.message));
  }
};

export const getResult = (formId, userId) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api.get(`/answer/${formId}/${userId}`).
      then((response) => {
        dispatch(getResultSuccess(response.data));
      });
  } catch (e) {
    return dispatch(hasError(e.message));
  }
};