import axios from 'axios';
const api = axios.create({
  baseURL: process.env.REACT_APP_QUIZ_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});
api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      const token_type = 'Bearer';
      if (token) {
        config.headers['Authorization'] = `${token_type} ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
);
export default api;
