import React, { useEffect } from "react";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
const FormInput = (props) => {
  const { form, openQuiz } = props;
  return (
    <div className="student-form-input">
      <div className="student-form-text">{form.name}</div>
      <KeyboardArrowRightIcon
        className="student-form-icon"
        onClick={() => window.open(`student/quiz/${form._id}`, "_blank")}
      />
    </div>
  );
};

export default FormInput;
