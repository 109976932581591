import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getForms} from '../../redux/slices/student';
import FormInput from './FormInput';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {logout} from '../../redux/slices/user';
import Profile from '../../Components/Profile/Profile';

const Index = () => {
  const {forms, isLoading, message, success} = useSelector(
      (state) => state.student);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getForms());
  }, [dispatch]);
  return (
      <>
        <Profile />
        <div className="student-forms">
          <div className="student-form-header">
            Quiz Forms
          </div>
          <div className="list-form">
          <div className="student-form">
            {forms.map(form => {
              if(form.publish){
              return <FormInput form={form}/>
              };
            })}
          </div>
          </div>
        </div>
      </>
  );
};

export default Index;