import React, {useCallback, useEffect} from 'react';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import AudiotrackOutlinedIcon from '@material-ui/icons/AudiotrackOutlined';
function FileDropeZone({setFiles,questionIndex,type}) {

  const { acceptedFiles, getRootProps, getInputProps , multiple=false } = useDropzone({ maxFiles:1});

  useEffect(()=>{
    if(acceptedFiles) {
      setFiles(acceptedFiles,questionIndex,type)
    }
  },[acceptedFiles])

  const files = acceptedFiles.map((file) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
  ));
  return (
      <section className="dropzone">
        <div {...getRootProps({ className: 'dropzone' })} className="dropzone-container" >
          <input {...getInputProps()} />
          <p className="dropZone-text">Drop your  {type} here or browse  </p>
          {type === 'image' ?  <ImageOutlinedIcon className="dropzone-icon"/> : <AudiotrackOutlinedIcon className="dropzone-icon"/>}

        </div>
        <aside>
          <ul>{files}</ul>
        </aside>
      </section>
  );
}

export default FileDropeZone;
