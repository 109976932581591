import React from 'react';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const FormModal = (props) => {
  const {modalValues, modalSetValues} = props;
  const {name, description,publish} = modalValues;
  const handleChange = (event) => {

    modalSetValues(
        prevState => ({...prevState, [event.target.name]: event.target.type === "checkbox"? event.target.checked : event.target.value}));
  };
  return (
      <div>
        <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Name"
            type="text"
            fullWidth
            value={name}
            onChange={handleChange}
        />
        <TextField
            autoFocus
            margin="dense"
            name="description"
            label="Description"
            type="text"
            fullWidth
            value={description}
            onChange={handleChange}
        />
        <FormControlLabel style={{marginTop:10}}
            control={<Switch checked={publish} onChange={handleChange} name="publish" />}
            label="Publish"
        />
      </div>
  );
};

export default FormModal;