import React, { useEffect, useState } from "react";
import CardContent from "@material-ui/core/CardContent";
import { useDispatch, useSelector } from "react-redux";
import {
  AddForm,
  deleteForm,
  editForm,
  getForms,
  editStatusForm,
} from "../../../redux/slices/superAdmin";
import Dialog from "../../../Components/Dialog/Dialog";
import FormModal from "./FormModal";
import { SnackBar } from "../../../Components/SnackBar";
import AddButtonHeader from "./AddButtonHeader";
import Switch from "@material-ui/core/Switch";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button, Container } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { createTheme } from "@material-ui/core/styles";

const User = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [values, setValues] = useState({
    fullName: "",
    role: "",
    email: "",
    password: "",
    active: true,
  });

  const { forms, message, success, formId } = useSelector(
    (state) => state.superAdmin
  );

  useEffect(() => {
    dispatch(getForms());
  }, [dispatch]);
  const submitForm = async () => {
    if (!values.id) {
      await dispatch(AddForm(values));
    } else {
      await dispatch(editForm(values.id, values));
    }
    setOpen(false);
    setOpenSnackBar(true);
    setValues({
      fullName: "",
      role: "",
      email: "",
      password: "",
    });
  };
  const handleFormAction = (form, action, checked) => {
    switch (action) {
      case "edit":
        setValues({
          fullName: form.fullName,
          role: form.role,
          email: form.email,
          password: form.password,
          id: form._id,
        });
        setOpen(true);
        break;
      case "delete":
        dispatch(deleteForm(form._id, ""));
        setOpenSnackBar(true);
        break;
      case "draft":
        dispatch(editStatusForm(form._id, !checked));
        break;
      default:
    }
  };

  const headCells = [
    {
      field: "_id",
      flex: 1,
      headerName: "ID",
      filter: "agNumberFilter",
      resizable: true,
      sortable: true,
    },

    {
      field: "fullName",
      flex: 1,
      headerName: "Full-Name",
      filter: "agTextColumnFilter",
      sortable: true,
      resizable: true,
    },
    {
      field: "email",
      flex: 1,
      headerName: "E-mail",
      filter: "agNumberFilter",
      resizable: true,
      sortable: true,
    },

    {
      field: "createdAt",

      headerName: "CreatedAt",
      flex: 1,
      sortable: true,
      filter: "agDateColumnFilter",
      resizable: true,

      filterParams:(e)=>filterParams,
      valueGetter:(e) => e?.data?.createdAt.substring(0, 10).split('-').reverse().join("/"),
    },
    {
      field: "updatedAt",
      sortable: true,
      headerName: "UpdatedAt",
      flex: 1,
      filter: "agDateColumnFilter",
      resizable: true,

      filterParams:(e)=>filterParams,
      valueGetter:(e) => e?.data?.createdAt.substring(0, 10).split('-').reverse().join("/"),
    },
    {
      field: "role",
      flex: 1,
      headerName: "Role",
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: "active",
      headerName: "Active/Desactive",
      sortable: true,
      resizable: true,
      flex: 1,
      valueFormatter: ({ value }) => value.active,
      cellRendererFramework: (form) => {
        return (
          <Switch
            checked={form.data.active}
            onChange={() =>
              handleFormAction(form.data, "draft", form.data.active)
            }
            name="active"
            inputProps={{
              "aria-label": "secondary checkbox",
            }}
          />
        );
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      cellRendererFramework: (form) => {
        return (
          <div className="form-list-actions">
            <Button>
              {" "}
              <EditIcon
                className="form-list-edit"
                onClick={() => handleFormAction(form.data, "edit")}
              />
            </Button>
            <Button>
              <DeleteIcon
                className="form-list-delete"
                onClick={() => handleFormAction(form.data, "delete")}
              />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyItems: "center",
        justifyContent: "center",
      }}
    >
      <Container className="form-card">
        <CardContent
          className="form-container"
          style={{
            padding: 0,
          }}
        >
          <AddButtonHeader
            buttonFn={() => setOpen(true)}
            buttonText={"Add New User"}
          />
          <div
            className="ag-theme-alpine"
            style={{ height: 730, width: "100%" }}
          >
            <AgGridReact
              rowData={forms}
              columnDefs={headCells}
              pagination={true}
              paginationPageSize={15}
            ></AgGridReact>
          </div>
          <Dialog
            open={open}
            setOpen={setOpen}
            ModalComponent={FormModal}
            modalAction={submitForm}
            modalValues={values}
            modalSetValues={setValues}
            titreBtn="save changes"
            btnTitel="Add new user :"
          />
          <SnackBar
            open={openSnackBar}
            setOpen={setOpenSnackBar}
            snackBarText={message}
            snackSeverity={success}
            formId={formId}
          />
        </CardContent>
      </Container>
    </div>
  );
};

export default User;


var filterParams = {
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split('/');
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};
