import React from "react";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Select } from "@material-ui/core";

const FormModal = (props) => {
  const { modalValues, modalSetValues } = props;
  const { fullName, role, email, password, active } = modalValues;
  const handleChange = (event) => {
    modalSetValues((prevState) => ({
      ...prevState,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    }));
  };
  return (
    <div>
      <TextField
        autoFocus
        margin="dense"
        name="fullName"
        label="fullName"
        type="text"
        fullWidth
        value={fullName}
        onChange={handleChange}
      />
      <br />
      <Select
        native
        value={role}
        onChange={handleChange}
        inputProps={{
          name: "role",
          id: "filled-age-native-simple",
        }}
      >
        <option aria-label="None" value="">
          --Select-Role---
        </option>
        <option value={"admin"}>Admin</option>
      </Select>{" "}
      <br />
      <TextField
        autoFocus
        margin="dense"
        name="email"
        label="email"
        type="email"
        fullWidth
        value={email}
        onChange={handleChange}
      />
      <TextField
        autoFocus
        margin="dense"
        name="password"
        label="password"
        type="password"
        fullWidth
        value={password}
        onChange={handleChange}
      />
      <FormControlLabel
        style={{ marginTop: 10 }}
        control={
          <Switch checked={active} onChange={handleChange} name="active" />
        }
        label="active"
      />
    </div>
  );
};

export default FormModal;
